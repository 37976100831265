.color-theme-kodinpro {
    &.navbar {
        background: #89BCBE;
    }
}

.page-content * img {
  max-width:100%;
}

#main-view .page {
  background-color: #fef6f5;

}

.md .item-input-wrap:after {
  display: none !important;
}

.webapp-container{
  max-width: 1245px;
  margin-left:auto;
  margin-right:auto;
  .col-100 {
    padding:15px;
  }
  @media screen and (max-width:800px) {
    .col-100 {
      padding-top:0px !important;
      padding-bottom:0px !important;
    }
  }
  .item-theme .item-inner::after {
    display:none !important;
  }
}
.webapp-container-small{
  max-width:852px;
  margin-left:auto;
  margin-right:auto;
}
.dialog-input {
  border: 1px solid #2d2e46 !important;
  margin-top:20px !important;
  padding:0px 10px !important;
}

@media (min-width: 768px) {
  .md .navbar ~ * .page-content, .md .navbar ~ .page-content {
      padding-top: 30px;
  }

  .device-desktop .page .navbar ~ .page-content {
    padding-top: 130px;
  }

  .device-desktop .page .navbar ~ .webapp-navbar ~ .page-content {
    padding-top: 210px;
  }
}
.photo-browser .title {
  display: none;
}
html.device-ios.device-iphone-x .statusbar, .toolbar  {
  background:#f0f3f4 !important;
}
.ios.device-iphone-x .login-screen>.toolbar, .ios.device-iphone-x .page>.toolbar, .ios.device-iphone-x .panel>.toolbar, .ios.device-iphone-x .popup>.toolbar, .ios.device-iphone-x .view>.toolbar, .ios.device-iphone-x .views>.toolbar {
  height: 90px !important;
}
.popup-close span {
  display: none !important;
}
html.with-statusbar.device-ios .panel {
  height: calc(100% - env(safe-area-inset-top) - 10px) !important;
  top: calc(10px + env(safe-area-inset-top));
}

html.with-statusbar.device-ios .framework7-root {
  padding-top: calc(10px + env(safe-area-inset-top)) !important;
}

html.with-statusbar .statusbar {
  background-color: #2d2e46 !important;
}

html.with-statusbar.device-ios .statusbar {
  height: calc(10px + env(safe-area-inset-top)) !important;
}

.item-theme .item-title{
  color: #344754;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  white-space: normal!important;
}
.item-theme .item-inner{
  padding-right:0px !important;
}
.item-theme .icon-radio{
  border-color: #ea5433 !important;
  background: white !important;
  border-width:1px;
  border-radius:50%;
  margin-right:10px !important;
  height:30px;
  width:30px;
  position:relative !important;
  margin-top:0px !important;
  top:auto !important;
  right:auto !important;
}
.item-theme label.item-radio input[type="radio"]:checked ~ .icon-radio:after,
.item-theme label.item-radio input[type="radio"]:checked ~ * .icon-radio:after,
.item-theme .radio input[type="radio"]:checked ~ .icon-radio:after {
  transform: scale(1);
}
.item-theme .icon-radio:after{
  transform: scale(0);
  width: 24px !important;
  height: 24px !important;
  left: 7px !important;
  top: 7px !important;
  background-color:#ea5433 !important;
  width: 10px;
  height: 10px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 50%;
}
.item-theme .item-content {
  width: 100%;
}
.item-theme .icon-checkbox{
  border-color: #ea5433;
  background: white;
  border-width:1px;
  border-radius:50%;
  margin-right:10px !important;
  height:30px;
  width:30px;
}
.item-theme .icon-checkbox:after{
  left: 5px !important;
  top: 5px !important;
}

.item-theme input[type=checkbox]:checked ~ .icon-checkbox{
  border-color: #ea5433 !important;
  background-color: #ea5433 !important;
}

.input-clear-button {
  right:5px;
}
.md {
  .navbar~* .page-content,.navbar~.page-content {
    padding-top: 60px;
  }
}
.ios {
    .overflow-scroll-auto {
      -webkit-overflow-scrolling: auto !important;
    }
    .item-theme input::placeholder,
    .item-theme textarea::placeholder,
    .item-theme .inline-label input::placeholder{
      position:relative;
      top:3px;
    }
    .theme-dark .panel{
      background:black;
    }
    .button {
      height: 36px;
    }
    .navbar:after {
      display:none !important;
    }
    .navbar {
      height: 65px;
      background: #F0F3F4 !important;
    }
    .list ul {
      background:transparent !important;
    }
    .navbar~.page-content, .navbar~:not(.no-navbar) .page-content {
      padding-top: 60px;
    }
}
